import * as React from "react";

// https://svgporn.com/#search=Discord
// https://www.svgminify.com/
export default function Discord() {
	return (
		<svg
			preserveAspectRatio="xMidYMid"
			viewBox="0 0 256 199"
			xmlns="http://www.w3.org/2000/svg"
			className="social-icon"
			aria-hidden={true}
		>
			<path
				d="m216.86 16.597c-16.571-7.7533-34.29-13.388-52.815-16.597-2.275 4.1132-4.9329 9.6455-6.7655 14.046-19.692-2.9614-39.203-2.9614-58.533 0-1.8322-4.4009-4.5504-9.9333-6.8458-14.046-18.545 3.2085-36.284 8.864-52.855 16.638-33.425 50.509-42.485 99.763-37.955 148.32 22.169 16.555 43.653 26.612 64.775 33.193 5.2151-7.1774 9.8662-14.807 13.873-22.848-7.6312-2.8997-14.94-6.4781-21.846-10.632 1.8322-1.3574 3.6243-2.7765 5.3558-4.2367 42.123 19.702 87.89 19.702 129.51 0 1.7518 1.4602 3.5436 2.8793 5.3558 4.2367-6.9265 4.1746-14.256 7.753-21.887 10.653 4.0069 8.0204 8.638 15.671 13.873 22.848 21.142-6.5809 42.646-16.637 64.815-33.213 5.3158-56.288-9.0809-105.09-38.056-148.36zm-131.38 118.5c-12.645 0-23.015-11.805-23.015-26.18 0-14.375 10.148-26.2 23.015-26.2 12.867 0 23.236 11.804 23.015 26.2 0.020002 14.375-10.148 26.18-23.015 26.18zm85.051 0c-12.645 0-23.015-11.805-23.015-26.18 0-14.375 10.148-26.2 23.015-26.2 12.866 0 23.236 11.804 23.015 26.2 0 14.375-10.148 26.18-23.015 26.18z"
				// fill="#5865F2"
			/>
		</svg>
	);
}
